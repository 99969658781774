import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Teaser from "../components/teaser"

export default class GetInvolved extends React.Component {
  render() {
    return (
      <Layout title="Get Involved">
        <Seo title="Get Involved"/>
        <div className="container">
          <div className="row justify-content-center">
            <section className="col-12 col-sm-12 col-md-6 col-xl-4">
              <Teaser title="Equip a Leader" 
                      description={`Equip young Native Christian leaders to reach their communities for Jesus and to be a living example of hope to broken young people.`}
                      url="/get-involved/equip-a-leader"
                      thumbnail="/video-thumbnails/equip-a-leader.jpg" />
            </section>
          </div>
        </div>
      </Layout>
    )
  }
}

