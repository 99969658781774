import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby";
import playButton from '../images/play-button.png'

export default class Teaser extends React.Component {
  render() {
    return (
      <article className="teaser">
        <div className="teaser-video">
          <Link to={this.props.url}><img src={this.props.thumbnail} alt={this.props.title} className="video-thumbnail" /></Link>
          <Link to={this.props.url}><img src={playButton} className="play-overlay" alt="" /></Link>
        </div>
        <h3><Link to={this.props.url}>{this.props.title}</Link></h3>
        {(this.props.author || this.props.location) && <div className="container author-location"><div className="row">
          {this.props.author && <div className="col-6 col-sm-4">
              <Link to={this.props.url}><i className="fas fa-user mr-1"></i> By <em>{this.props.author}</em></Link>
            </div>}
            {this.props.location && <div className="col-6 col-sm-4">
              <Link to={this.props.url}><i className="fas fa-map-marker-alt mr-1"></i> In <em>{this.props.location}</em></Link>
            </div>}
        </div></div>}
        <p><Link to={this.props.url}>{this.props.description}</Link></p>          
      </article>
    )
  }
}

Teaser.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  author: PropTypes.string,
  location: PropTypes.string
}
